import React from 'react'
import Auth from '../component/Auth'

function Browser() {
    return (
        <>
        <Auth/>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white">
                <div className="px-6 py-8 relative z-20 w-full">
                    <div className="flex justify-start items-center pb-8 mr-8">
                        <a  href="/"  className="flex justify-center items-center p-2 rounded-full bg-g300 text-n900">
                            <i className="ph-bold ph-caret-left" />
                        </a>
                        <div className="flex justify-center items-center w-full">
                            <h1 className="font-semibold text-2xl">Withdraw</h1>
                        </div>
                    </div>
                    <div className="">
                        <a href="InstantWithdraw"  className="w-full flex justify-between items-center gap-6 border border-white border-opacity-5 bg-white bg-opacity-5 p-4 rounded-xl">
                            <div className="flex justify-start items-center gap-3">
                               <div className="p-2 rounded-full bg-white bg-opacity-5 flex justify-center items-center size-12">
                                    <img src="assets/images/icons/usdtIcons.png" alt="" />
                                </div>
                                <div className="">
                                    <p className="font-semibold">Instant USDT</p>
                                    <p className="text-n70 text-sm">
                                        Balance: {localStorage.getItem('instant_withdraw')} 
                                    </p>
                                </div>
                            </div>
                            <i className="ph ph-caret-right text-g300" />
                        </a>
                    </div>
                    <div className="pt-6">
                        <a  href="/stakeWithdraw"  className="w-full flex justify-between items-center gap-6 border border-white border-opacity-5 bg-white bg-opacity-5 p-4 rounded-xl">
                            <div className="flex justify-start items-center gap-3">
                            <div className="p-2 rounded-full bg-white bg-opacity-5 flex justify-center items-center size-12">
                                 <img src="assets/images/icons/kcoinIcon.avif" alt="" />
                                </div>
                                <div className="">
                                    <p className="font-semibold">Stake Reward</p>
                                    <p className="text-n70 text-sm"> Balance: {localStorage.getItem('stake_k_coin_remain')} </p>
                                </div>
                            </div>
                            <i className="ph ph-caret-right text-g300" />
                        </a>
                    </div>

                    <div className="pt-6">
                        <a href="/PackageWithdraw"  className="w-full flex justify-between items-center gap-6 border border-white border-opacity-5 bg-white bg-opacity-5 p-4 rounded-xl">
                            <div className="flex justify-start items-center gap-3">
                            <div className="p-2 rounded-full bg-white bg-opacity-5 flex justify-center items-center size-12">
                                 <img src="assets/images/icons/kcoinIcon.avif" alt="" />
                                </div>
                                <div className="">
                                    <p className="font-semibold">Package Reward</p>
                                    <p className="text-n70 text-sm"> Balance: {localStorage.getItem('package_k_coin_remain')} </p>
                                </div>
                            </div>
                            <i className="ph ph-caret-right text-g300" />
                        </a>
                    </div>

                    <div className="pt-6">
                        <a href="/ResidualWithdraw" className="w-full flex justify-between items-center gap-6 border border-white border-opacity-5 bg-white bg-opacity-5 p-4 rounded-xl">
                            <div className="flex justify-start items-center gap-3">
                            <div className="p-2 rounded-full bg-white bg-opacity-5 flex justify-center items-center size-12">
                                 <img src="assets/images/icons/kcoinIcon.avif" alt="" />
                                </div>
                                <div className="">
                                    <p className="font-semibold">Residual Reward</p>
                                    <p className="text-n70 text-sm"> Balance: {localStorage.getItem('residual_k_coin_remain')} </p>
                                </div>
                            </div>
                            <i className="ph ph-caret-right text-g300" />
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Browser